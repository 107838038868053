import React from 'react';
import { ReactComponent as TacoAccentLeft } from '../../assets/images/Taco Accent Left.svg';
import { ReactComponent as TacoAccentRight } from '../../assets/images/Taco Accent Right.svg';
import { ReactComponent as TitleUnderline } from '../../assets/images/TitleUnderline.svg';

interface SectionTitleProps {
  title: string;
  alternate?: boolean;
  left?: boolean;
}

export default function SectionTitle({ title, alternate, left }: SectionTitleProps) {
  return (
    <div className={`flex flex-col items-start ${left? 'md:justify-left' : 'justify-center'} mb-8 ml-4`}>
      {/* 0th row: Title underline */}
      {!alternate? '' :
        <div className={`flex ${left? 'md:justify-left' : 'justify-center'} w-full mt-2`}>
        {/* Option 1: If TitleUnderline is an imported React component */}
        <TitleUnderline className={`w-auto h-4 md:h-6 ${left? 'ml-8' : ''} `} />
      </div>}

      {/* First row: Taco accents + Title */}
      <div className={`flex items-start ${left? 'justify-left' : 'justify-center'} my-12 w-full`}>
      {/* Left Taco Accent */}
      <TacoAccentLeft
        className={`hidden md:block w-6 md:w-10 h-auto transform ${!alternate? '-translate-y-[15%]' : 'transform  rotate-[40deg] -scale-x-100 -translate-x-[40%] translate-y-[10%] w-10 md:w-14 translate'}`}
        aria-hidden="true"
      />

      {/* Title */}
      <h3 className="text-5xl md:text-7xl font-nexa text-center text-redz-white relative">
        {title}
      </h3>

      {/* Right Taco Accent */}
      <TacoAccentRight
        className={`hidden md:block w-6 md:w-10 h-auto transform ${!alternate? '-translate-y-[15%]' : 'transform -rotate-[26deg] -scale-x-100 translate-x-[40%] translate-y-[10%] w-10 md:w-14 translate'}`}
        aria-hidden="true"
      />
    </div>

      {/* Second row: Title underline */}
      <div className={`flex ${left? 'md:justify-left' : 'justify-center'} w-full mt-2`}>
        {/* Option 1: If TitleUnderline is an imported React component */}
        <TitleUnderline className={`w-auto h-4 md:h-6 ${left? 'ml-8' : ''} `} />
      </div>
    </div>
  );
}