import React from "react";
import { Welcome } from "../Home/HomeHero/WelcomeSection";

const Hero: React.FC = () => {
  return (
    <div 
      className="
        flex 
        flex-col 
        md:flex-row 
        w-full 
        pt-20  /* Enough top spacing for large screens */
        px-4 
        md:px-12
        items-center
        justify-center
      "
    >
      {/* Left Column (md: 2/3 width) */}
      <div className="w-full md:w-2/3 mb-8 md:mb-0 md:pr-8">
        <div className="flex flex-col items-center text-center">
          <Welcome />
          <div className="
            text-redz-white 
            font-redsox 
            text-base 
            md:text-2xl 
            lg:text-3xl 
            mt-4 
            md:mt-8
          ">
            Where passion, tradition, and innovation come together to create an 
            extraordinary dining experience. Our story begins with Yona Redz, a 
            culinary visionary whose journey is as rich and flavorful as the dishes 
            we serve.
          </div>
        </div>
      </div>

      {/* Right Column (md: 1/3 width) */}
      <div className="w-full md:w-1/3 flex items-center justify-center">
        <img 
          src="https://redz-bucket.s3.us-west-2.amazonaws.com/logo.webp" 
          alt="Yona Redz Logo" 
          className="w-[60%] md:w-1/2 h-auto"
        />
      </div>
    </div>
  );
};

export default Hero;