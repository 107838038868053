import React from 'react';
import Banner from '../common/Banner';

// Shared styling for the text sections
const paragraphClass = `
  text-redz-white 
  font-myriad 
  text-balance
  text-sm 
  md:text-base 
  lg:text-lg 
  leading-relaxed 
  text-center
  mt-4
`;

interface SectionContent {
  title: string;
  text: string;
}

const sections: SectionContent[] = [
  {
    title: "Yona's Early Inspiration",
    text: `"Yona's love for food began at an early age, growing up in a family where the kitchen was the heart of the home. His grandmother's recipes, passed down through generations, ignited a passion for cooking that would shape his future. He learned the value of fresh ingredients, the art of slow cooking, and the joy of sharing a meal with loved ones."`
  },
  {
    title: "The Birth of a Dream",
    text: `"While Yona explored various career paths, his heart always led him back to the kitchen. It was during a visit to a small coastal town in Mexico that he encountered birria, a dish that would become his signature. The rich, slow-cooked flavors captivated him, and he knew he wanted to bring this experience to his community."`
  },
  {
    title: "The Backyard Beginnings",
    text: `"In 2020, on Memorial Day, Yona took a leap of faith. He started hosting backyard pop-ups, sending out invitations just an hour before serving. These pop-ups became a sensation in Santa Barbara, with lines stretching down the block for his famous birria tacos. The community quickly embraced the luxury of enjoying authentic birria without the six-hour wait to slow cook it themselves."`
  },
  {
    title: "The Growth of a Legend",
    text: `"The buzz around Yona's pop-ups grew, with people sharing stories of Yona's culinary magic appearing in unexpected places, like the beach or hidden spots around town. These tales, whether true or fantastical, only added to the allure of Yona Redz."`
  },
  {
    title: "Opening of Redz",
    text: `"Encouraged by the overwhelming support and demand, Yona opened the doors to Redz. The restaurant quickly became a culinary landmark, known for its unique blend of tradition and modern flair. Every dish at Redz is a celebration of Yona's journey, from his grandmother's kitchen to the streets of Santa Barbara."`
  },
];

const AboutYonaSections: React.FC = () => {
  return (
    <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-4 mx-10 justify-items-center"
        >
            {sections.map((section, idx) => (
                 <div className={`w-full max-w-2xl px-4 py-8 items-center ${(idx === sections.length-1 && idx % 2 === 0)? 'md:col-span-2': ''}`} key={section.title}>
                    <div className="relative w-full">
                        <Banner 
                            classNameProp="w-full h-auto"
                        />
                        <h2 
                            className="
                            text-black 
                            font-redsox 
                            absolute 
                            top-1/2 
                            left-1/2 
                            -translate-x-1/2 
                            -translate-y-1/2 
                            text-xl 
                            md:text-2xl 
                            lg:text-3xl 
                            whitespace-nowrap
                            "
                        >
                            {section.title}
                        </h2>
                    </div>

                     {/* Paragraph text below the banner */}
                    <p className={paragraphClass}>
                        {section.text}
                    </p>
                </div>
            ))}
    </div>
    );
};

export default AboutYonaSections;