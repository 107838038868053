import React from 'react';
import StayUpdatedHeaderBorder from '../../../assets/images/StayUpdatedHeaderBorder.svg';
import StayUpdatedSVG from '../../common/StayUpdatedSVG';
import NewsletterButton from '../../common/NewletterButton';

const StayUpdatedSection: React.FC = () => {
    return (
        <section className="relative bg-[#EE3337] text-center py-16 lg:py-24">
            {/* Stay Updated SVG */}
            <div className="relative mx-auto max-w-[60%] pt-12">
                <StayUpdatedSVG />
            </div>

            {/* Newsletter Signup Title */}
            <h2 className="font-redsox text-redz-white text-4xl lg:text-6xl uppercase mt-8">
                Newsletter Signup
            </h2>

            {/* Newsletter Signup Form */}
            <div className="mt-8 mx-auto max-w-[80%] lg:max-w-[50%]">
                <NewsletterButton />
            </div>
        </section>
    );
};

export default StayUpdatedSection;