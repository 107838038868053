import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../stores/MenuStore';
import HeroSection from './HeroSection';
import SectionTitle from './SectionTitle';
import { ItemCard } from './ItemCard';
import SidesDrinksSection from './SidesDrinksSection';
import { ReactComponent as WeekendSpecialSVG } from '../../assets/images/WeekendSpecial.svg';
import {ReactComponent as ProteinChoices} from '../../assets/images/ProteinChoices.svg';
import {ReactComponent as PageTearDiv} from '../../assets/images/PageTearDiv.svg';
import TuesdaysAndHappyHourSection from './TuesdaysAndHappyHourSection';
import PostSpecialtiesSection from './PostSpecialitiesSection';
import Gallery from '../Home/Gallery';
import EnjoyRedzAtHomeSection from '../Home/EnjoyRedzAtHome';

const MenuPage: React.FC = observer(() => {
  const {
    fetchAllMenuItems,
    loading,
    error,
    specialtiesItems,
    singleItems,
    kidsMenuItems,
    localSpecialsItems,
    // sidesItems, drinksItems handled in SidesDrinksSection
  } = menuStore;

  useEffect(() => {
    fetchAllMenuItems();
  }, [fetchAllMenuItems]);

  if (loading) return <p className="text-white bg-black p-4">Loading menu...</p>;
  if (error) return <p className="text-red-500 bg-black p-4">Error: {error}</p>;

  return (
    <div className="bg-black text-white">
      <HeroSection />
      <div id='menu'/>
      {/* Local Favorites Section (3-col), narrower columns */}
      <section className="py-8 px-4" id='favorites'>
        <SectionTitle title="LOCAL FAVORITES" />
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          {localSpecialsItems.map(item => (
            <ItemCard key={item.id} item={item} />
          ))}
        </div>
      </section>

      {/* TACO TUESDAY and Happy Hour */}
      <TuesdaysAndHappyHourSection />

      {/* SPECIALTIES (3-col) */}
      <section className="relative py-8 px-4 lg:-mt-24" id="specialties">
        <SectionTitle title="SPECIALTIES" alternate={true} left={true}/>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {specialtiesItems.map(item => (
            <ItemCard key={item.id} item={item} />
          ))}
        </div>
        <PostSpecialtiesSection />
        <ProteinChoices className='w-full mt-8' />
        <p className='text-center text-redz-white font-myriad mb-8 text-xl'>*Consome is optional but recommended for Specialties. Specialties do NOT include drinks.<br/>**ONLY TWO Protein choices per item.</p>
      </section>

      {/* Weekend Specials with redz-white BG + custom SVG */}
      <section className="bg-redz-white relative -mb-8">
        {/* The SVG centered at the top */}
        <div className="flex justify-center -translate-y-4">
          <WeekendSpecialSVG className="w-full" />
        </div>
      </section>

      {/* SINGLE ITEMS (3-col) */}
      <section className="py-8 px-4" id="single-items">
        <SectionTitle title="SINGLE ITEMS" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {singleItems.map(item => (
            <ItemCard key={item.id} item={item} />
          ))}
        </div>
        <ProteinChoices className='w-full mt-8' />
        <p className='text-center text-redz-white font-myriad mb-8 text-xl'>**ONLY TWO Protein choices per item.</p>
        <PageTearDiv />
      </section>

      {/* SIDES & DRINKS (2-col, with special "Side of Meat" grouping) */}
      <SidesDrinksSection />

      {/* KIDS MENU (3-col) */}
      <section className="py-8 px-4" id="kids-menu">
        <SectionTitle title="KIDS MENU"/>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {kidsMenuItems.map(item => (
            <ItemCard key={item.id} item={item} kids_menu={true}/>
          ))}
        </div>
      </section>

      <Gallery/>
      <EnjoyRedzAtHomeSection />
    </div>
  );
});

export default MenuPage;