import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../api/client'; // Your Axios or Fetch wrapper
import { MenuItem } from '../types';

//
// 3. MobX Store
//
class MenuStore {
  items: MenuItem[] = [];
  loading = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // Fetch ALL menu items once
  async fetchAllMenuItems() {
    this.loading = true;
    this.error = null;

    try {
      // The response data should match our MenuItem[] shape
      const response = await API.get<MenuItem[]>('/api/menu-items/');
      const data = response.data.map(item => {
        if (typeof item.base_price === 'string') {
          const parsed = parseFloat(item.base_price);
          // If parseFloat fails, fallback to 0 or undefined
          item.base_price = isNaN(parsed) ? undefined : parsed;
        }
        return item;
      });

      this.items = data;
    } catch (err: any) {
      console.error('Error fetching menu items:', err);
      this.error = err.message || 'Failed to fetch menu items.';
      toast.error(this.error);
    } finally {
      this.loading = false;
    }
  }

  //
  // 4. Computed getters for each category
  //
  get weekendSpecialItems() {
    return this.items.filter(item => item.category?.name === 'Weekend Special');
  }

  get specialtiesItems() {
    return this.items.filter(item => item.category?.name === 'Specialties');
  }

  get singleItems() {
    return this.items.filter(item => item.category?.name === 'Single Items');
  }

  get kidsMenuItems() {
    return this.items.filter(item => item.category?.name === 'Kids Menu');
  }

  get sidesItems() {
    return this.items.filter(item => item.category?.name === 'Sides');
  }

  get drinksItems() {
    return this.items.filter(item => item.category?.name === 'Drinks');
  }

  get happyHourItems() {
    return this.items.filter(item => item.category?.name === 'Happy Hour Specials');
  }

  get tacoTuesdayItems() {
    return this.items.filter(item => item.category?.name === 'Taco Tuesday');
  }

  get localSpecialsItems() {
    return this.items.filter(item => item.category?.name === 'Local Specials');
  }
}

//
// 5. Export the store as a singleton
//
const menuStore = new MenuStore();
export default menuStore;