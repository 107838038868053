import React from 'react';
import { ReactComponent as DollarSign } from '../../assets/images/DollarSign.svg';
import { ReactComponent as DollarSignWhite } from '../../assets/images/DollarSignWhite.svg';

interface ItemCardProps {
  item: {
    name: string;
    description?: string;
    base_price?: number | string;
  };
  kids_menu?: boolean;
}

// For standard 3-col sections (Weekend, Single Items, etc.)
export const ItemCard: React.FC<ItemCardProps> = ({ item, kids_menu }) => {
  // Format numeric or string prices
  const formatPrice = (price?: number | string) => {
    if (price == null) return null;
    const num = typeof price === 'number' ? price : parseFloat(price);
    if (isNaN(num)) return null;
    return num.toFixed(2);
  };

  const priceString = formatPrice(item.base_price);

  return (
    <div className="text-center mx-auto w-full my-4 md:my-0">
      {/* Larger name, centered */}
      <h4 className="text-redz-red font-redsox text-2xl md:text-3xl">
        {item.name}
      </h4>

      {/* Description: clamp to 2 lines, centered */}
      {item.description && (
        <p className="text-redz-white mt-1 mx-auto max-w-[14rem] line-clamp-3">
          {item.description}
        </p>
      )}

      {/* Price with a DollarSign */}
      {priceString && (
        <div className={`flex items-baseline justify-center ${!kids_menu? 'text-redz-red' : 'text-redz-white'} text-2xl font-nexa font-bold mt-4`}>
          {!kids_menu? <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" /> : <DollarSignWhite className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />}
          {priceString}
        </div>
      )}
    </div>
  );
};