import React from 'react';
import { ReactComponent as LunchSpecialSvg } from '../../assets/images/LunchSpecial.svg';
import { ReactComponent as DollarSign } from '../../assets/images/DollarSign.svg';

export default function PostSpecialtiesSection() {
  return (
    <section className="py-8 px-4">
      {/* Two columns on md+, single column on smaller screens */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">

        {/* LEFT COLUMN: Disclaimers in redz-white, centered */}
        <div className="flex flex-col justify-center items-center text-center text-redz-white px-4">
          <p className="mb-2 text-balance">
            *Consome is optional but recommended for Specialties. <br/> 
            Specialties do NOT include drinks. <br/>
            *ONLY TWO Protein choices per item.
          </p>
        </div>

        {/* RIGHT COLUMN: LunchSpecial SVG + text */}
        <div className="flex flex-col items-center text-center px-4">
          {/* The Lunch Special SVG */}
          <LunchSpecialSvg className="w-full max-w-sm h-auto mx-auto" />

          {/* "Monday - Friday till 3:00pm" in redsox + redz-red */}
          <p className="text-redz-red font-redsox text-xl md:text-2xl lg:text-3xl mt-4">
            Monday - Friday 'til 3:00pm
          </p>

          {/* Description in redz-white */}
          <p className="text-redz-white mt-2 text-base md:text-lg lg:text-xl max-w-md mx-auto">
            2 Quesotacos, Rice, Beans, Consome w/ your choice of protein
          </p>

          {/* Price in $15 style, with a smaller dollar sign if you want to match your usual approach */}
          <div className="flex items-baseline justify-center text-redz-red text-2xl font-nexa font-bold mt-4">
            <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />
            15
          </div>
        </div>

      </div>
    </section>
  );
}