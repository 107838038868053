import React from "react";

interface BannerProps{
    color?: string;
    classNameProp?: string;
}
const Banner: React.FC<BannerProps> = ({ color, classNameProp }) => (
    <svg id={`Banner${color}_2`} data-name={`Banner${color} 2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476.39 57.56" className={classNameProp || ''}>
    <defs>
        <style>
        {`
            .Banner${color}-1 {
                fill: ${color === 'red' ? '#EE3337': '#efe2ca'};
                fill-rule: evenodd;
            }       
        `}
        </style>
    </defs>
    <g id={`Banner${color}_1`} data-name={`Banner${color} 1`}>
        <path className={`Banner${color}-1`} d="M3.59.5S132.95,1.91,266.87.5c133.91-1.41,205.47.63,205.47.63,0,0,2.53.7.72,1.23-1.82.53-6.91.31-4.82.93,2.09.62,4,.13,3.55.79-.45.66-3.09.44-3,.84.09.4,4.27.09,2.73.84-1.55.75-4.18.53-3.36,1.01.82.49,3.45.57,2.27.93-1.18.35,3,.26,1.09,1.01-1.91.75-4.36.66-2.27.97,2.09.31,1.45,1.24,1.45,1.24,0,0-3.55.31-1.09,1.19,2.45.88,5.64-.44,1.91,1.01-3.73,1.46-3.45,1.72-3.45,1.72,0,0,9.77,1.98,5.66,2.25-4.11.26,6.16,1.06-.38,1.37-6.55.31-1.18,1.41-4.27,4.01-3.09,2.6-7.73.57-2,3.13,5.73,2.56,13.27,1.46,6.91,3.75-6.36,2.29-7.45,3.18-7.45,3.18,0,0,7.64,1.19,4.55,2.51-3.09,1.32-5.64,1.5-3.91,2.07,1.73.57-1,1.15-1,1.15,0,0,14.09,4.59,7.55,7.41-6.55,2.82-11.82,1.06-9.27,2.82,2.55,1.76,9.45,4.06,2.73,4.94-6.73.88,4.36-.71,4.18,2.12,0,0-58.42,1.41-93.76,1.94-35.34.53-252.43-1.85-252.43-1.85l-121.55.79s-2.09-.88.45-1.06c2.55-.18,4-.69,1.45-.83-2.55-.14-6.34.21-2.72-.58,3.63-.79,7.26,0,4.08-.93-3.18-.93-5.82-.35-4.82-1.23,1-.88,4.82-.53,3.91-1.15-.91-.62-2.55-2.78-1.27-3.35,1.27-.57,5.64.66,1.64-.84s-7.45-.88-3.55-1.9c3.91-1.01,8.09-.4,4.27-1.32-3.82-.93-3.82-2.12-1.64-3.22,2.18-1.1,5.64-2.96,2.91-3.4-2.73-.44,2-.49,2.64-1.54.64-1.06,3-.75-1.27-1.46-4.27-.71-3.91-.93-1.45-1.19,2.45-.26,4.09-1.9,2.64-2.03-1.45-.13-8.55-1.15-4.27-2.12,4.27-.97,3.64-1.59,3.64-1.59,0,0-7.82-.22-6.82-3.09,1-2.87,6.45-1.41,3.64-3.04-2.82-1.63-7.09-.79-5.27-2.07,1.82-1.28,8.09-1.46,6.82-2.16-1.27-.71-8.45-.22-8.27-1.46.18-1.24,6.55-.35,4.36-1.59-2.18-1.23,1.82-2.03.09-2.91-1.73-.88,4.09-.35.27-1.5-3.82-1.15-2.18-1.5-1.18-2.16,1-.66,2.45-.88.45-1.41-2-.53-.73-.44-.73-.44,0,0,5.64-.31,2.09-.88-3.55-.57-5.09,1.06-4-.66C2.77,1.6-3.86,1.34,3.59.5Z"/>
    </g>
    </svg>
);

export default Banner;