import React from "react";
import Hero from "./Hero";
import { ReactComponent as FramedPhoto } from "../../assets/images/PhotoFramed.svg";
import AboutYonaSections from "./AboutYonaSections";
import StayUpdatedSection from "../Home/StayUpdated";
import LocationSection from "../common/LocationSection";
import OurVision from "./OurVision";
import TheJourney from "./TheJourney";
import SignatureDishBirth from "./SignatureDishBirth";

const AboutUs: React.FC = () => {
  return (
    <div className="mt-[15vh] bg-black text-white">
      <Hero />
      {/* Frame photo below hero */}
      <div className="flex justify-center my-6">
        <FramedPhoto className="w-full h-auto" />
      </div>
      <AboutYonaSections />
      <SignatureDishBirth />
      <OurVision/>
      <TheJourney/>
      <StayUpdatedSection/>
      <LocationSection />
    </div>
  );
};

export default AboutUs;