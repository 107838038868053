import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as LeftArrow } from '../../../assets/images/Arrow Left.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/Arrow Right.svg';
import { ReactComponent as Highlittext } from '../../../assets/images/GoToMenu Underline.svg';
import { Link } from "react-router-dom";

const GoToMenuSection: React.FC = () => (
    <motion.div
        className="relative mb-4 mt-4 w-full flex flex-col items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 4, duration: 1 }}
    >
        <Link
 
      to="/menu" 
      className="flex items-center justify-center hover:scale-110 transition-transform duration-300"
    >
     <div className="flex items-center justify-center hover:scale-110 transition-transform duration-300">
            <LeftArrow className="w-4 h-4 md:w-6 md:h-6 mr-2 hover:scale-110 transition-transform duration-300" />
            <span className="text-white font-redsox text-lg md:text-xl">Go to menu</span>
            <RightArrow className="w-4 h-4 md:w-6 md:h-6 ml-2 hover:scale-110 transition-transform duration-300" />
        </div>
    </Link>
        
        <Highlittext className="mt-2 w-24 md:w-32 h-auto" />
    </motion.div>
);

export default GoToMenuSection;