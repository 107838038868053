import React from 'react';
import MenuHeroLabel from './MenuHeroLabel';

// Import your new SVG components
import BottomMenuNav from './BottomMenuNav';
import SideMenuNav from './SideMenuNav';

export default function HeroSection() {
  function scrollToSection(sectionId: string) {
    const el = document.getElementById(sectionId);
    if (el) {
      // Get the element's offset from the top of the page
      const yOffset = -100; // offset in px so the title is fully visible
      const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
  
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
  return (
    <div className="relative w-full h-[85vh] md:h-[90vh] overflow-hidden pt-[15vh] z-30">
      {/* Hero Image */}
      <img
        src={"https://redz-bucket.s3.amazonaws.com/MenuHeroTaco.webp"}
        alt="Street Tacos"
        className="w-auto md:w-full h-full md:h-auto object-cover"
      />

      {/* SideMenuNav SVG on the left side, z-index > 20 */}
      <SideMenuNav
        scroll={scrollToSection}
      />

      {/* BottomMenuNav SVG at the bottom-right */}
      <BottomMenuNav
        scroll={scrollToSection}
      />

      {/* Overlay with bottom-right positioning for MenuHeroLabel */}
      <div className="absolute bottom-4 right-4 md:bottom-8 md:right-8 h-auto w-[40vw]">
        <MenuHeroLabel
          title="Street Taco"
          description="Soft Corn Tortillas (small), Red Onion, Cilantro w/ your choice of protein"
        />
      </div>
    </div>
  );
}