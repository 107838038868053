import React from "react";
import { ReactComponent as OfRedzFire } from '../../assets/images/OfRedzFire.svg';
import Banner from "../common/Banner";
import Divider from "../common/Divider";

interface SectionContent {
  title: string;
  text: string;
}

const sections: Array<SectionContent> = [
  {
    title: "2020 - The First Pop-Up:", 
    text: "On Memorial Day, Yona hosts his first backyard pop-up, sparking a culinary revolution in Santa Barbara."
  },
  {
    title: "2020 - The Birth of Birria:",
    text: "Yona's birria tacos become an instant hit, with lines forming down the block at each event."
  },
  {
    title: "2021 - The Pop-Up Craze:",
    text: "Redz pop-ups take over the city, with locations popping up at the beach, in hidden gardens, and even on rooftops. The buzz reaches new heights as the community eagerly anticipates where Yona will pop up next."
  },
  {
    title: "2022 - Opening of Redz:",
    text: "After months of planning and preparation, Yona opens the doors to Redz. The restaurant is an instant success, drawing in food lovers from all over Santa Barbara."
  },
  {
    title: "2023 - Recognition and Growth:",
    text: "Redz receives local acclaim and becomes a must-visit destination for both locals and tourists alike. Yona's vision continues to grow as the restaurant expands its offerings and deepens its connection with the community."
  }
];

// A shared paragraph style
const paragraphClass = `
  text-black
  font-myriad
  text-balance
  text-center
  text-sm
  md:text-base
  lg:text-lg
  leading-loose
`;

const TheJourney: React.FC = () => {
  return (
    <div className="relative bg-redz-white text-center py-24 space-y-6">
        <Divider classNameProps="absolute -top-4 sm:-top-8 md:-top-10 lg:-top-16" color="white"/>
      {/* Title */}
      <h3 className="relative text-6xl font-nexa text-redz-red">
        The Journey
      </h3>

      {/* Optional: The OfRedzFire icon */}
      <OfRedzFire className="relative h-10 md:h-16 -translate-x-1/2 left-1/2" />

      {/* Intro Paragraph */}
      <p className="
        text-3xl
        sm:text-4xl
        md:text-5xl
        text-redz-red
        pt-4
        text-balance
        mx-auto
        max-w-xl
        md:max-w-5xl
        font-redsox
      ">
        Redz is more than just a restaurant; it's the culmination of a journey 
        that began with a simple idea and grew into a beloved community treasure. 
        Explore the milestones that have shaped Redz into what it is today.
      </p>

      {/* Sections */}
      <div className="pt-16 space-y-8 px-4 md:px-8">
        {sections.map((section) => (
          <div 
            key={section.title} 
            className="
              grid
              grid-cols-1
              md:grid-cols-2
              items-center
              gap-4
              max-w-5xl
              mx-auto
            "
          >
            {/* Left Column: Banner + Title */}
            <div className="flex flex-col items-center md:items-end">
              <div className="relative w-full max-w-md mx-auto">
                {/* Banner with red color */}
                <Banner color="red" classNameProp="w-full h-auto mx-auto" />

                {/* Overlaid Title */}
                <h2 
                  className="
                    text-redz-white 
                    font-redsox 
                    absolute 
                    top-1/2 
                    left-1/2 
                    -translate-x-1/2 
                    -translate-y-1/2 
                    text-lg
                    sm:text-xl
                    md:text-2xl
                    lg:text-3xl
                    whitespace-nowrap
                  "
                >
                  {section.title}
                </h2>
              </div>
            </div>

            {/* Right Column: Paragraph */}
            <div className="flex items-center justify-center">
              <p className={`mx-auto ${paragraphClass} max-w-md`}>
                {section.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheJourney;