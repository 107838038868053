import React from 'react';

// Taco Tuesday assets
import { ReactComponent as TacoSvg } from '../../assets/images/TacoTuesday/Taco.svg';
import { ReactComponent as AllDayBannerSvg } from '../../assets/images/TacoTuesday/All_Day_Banner.svg';

// Happy Hour assets
import { ReactComponent as HappyHourSvg } from '../../assets/images/HappyHour.svg';
import { ReactComponent as HappyHourTextSvg } from '../../assets/images/HappyHourText.svg';

const TuesdaysAndHappyHourSection: React.FC = () => {
  return (
    <section className="bg-black text-white py-10 px-4 flex flex-col lg:flex-row items-start justify-center">
      {/* LEFT SIDE: TACO TUESDAY (Stacks on mobile, half-width on md+) */}
      <div className="relative w-full lg:w-1/2 h-[24rem] md:h-auto mb-8 md:mb-0 flex flex-col justify-center">
        
        {/* Absolutely-Positioned Taco (partial off-screen) */}
        <div className='flex lg:absolute lg:bottom-[17rem] lg:-left-[6rem] items-center justify-center mt-8'>
          <TacoSvg className="w-[20rem] lg:w-[30rem] h-auto" />
        </div>

        {/* Title/Banner Overlays */}
        <div className="relative z-10 mx-auto text-center lg:mt-32">
          {/* All Day Banner */}
          <AllDayBannerSvg className="mx-auto h-auto w-[14rem] lg:w-[18rem] mb-3" />
          
          {/* TACO TUESDAY Title */}
          <h2 className="font-nexa text-redz-red text-4xl md:text-5xl lg:text-6xl whitespace-nowrap">
            TACO TUESDAY
          </h2>

          {/* Single-line Tacos pricing */}
          <p className="font-redsox text-redz-white text-2xl md:text-3xl lg:text-4xl mt-4 mb-16 whitespace-nowrap text-balance">
            $4 Queso Tacos<br className='flex md:hidden'/><div className='hidden md:flex'> &nbsp;</div>$2.50 Street Tacos
          </p>
        </div>
      </div>

      {/* RIGHT SIDE: HAPPY HOUR (Stacks on mobile, half-width on md+) */}
      <div className="w-full lg:w-1/2 flex flex-col items-center">
        {/* Happy Hour SVG */}
        <div className="w-full max-w-sm md:max-w-md lg:max-w-lg mx-auto">
          <HappyHourSvg className="w-full h-auto" />
        </div>

        {/* The new "HappyHourText.svg" below the main HappyHourSvg */}
        <div className="-mt-8 ml-4 w-full flex justify-center">
          <HappyHourTextSvg className="w-[16rem] md:w-[20rem] h-auto text-redz-red font-nexa" />
        </div>
      </div>
    </section>
  );
};

export default TuesdaysAndHappyHourSection;