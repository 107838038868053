import React from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../stores/MenuStore';
import { ReactComponent as DollarSign } from '../../assets/images/DollarSignWhite.svg';
import { ReactComponent as TwentyOneSvg } from '../../assets/images/21Plus.svg';
import SectionTitle from './SectionTitle';

const SidesDrinksSection: React.FC = observer(() => {
  const { sidesItems, drinksItems } = menuStore;

  // Helper: format numeric or string price
  const formatPrice = (price?: number | string) => {
    if (price == null) return null;
    const num = typeof price === 'number' ? price : parseFloat(price);
    if (isNaN(num)) return null;
    return num.toFixed(2);
  };

  // Filter or group "Side of Meat" items
  const sideOfMeatItems = sidesItems.filter(item =>
    item.name.startsWith('Side of Meat:')
  );
  const nonSideOfMeatItems = sidesItems.filter(item =>
    !item.name.startsWith('Side of Meat:')
  );

  // -------------- ROW ITEM FOR SIDES --------------
  const StandardRowItem = ({ item }: { item: any }) => {
    const priceString = formatPrice(item.base_price);
    return (
      <div className="mb-4">
        {/* Title + Price in one line */}
        <div className="flex items-center justify-between">
          <h4 className="text-redz-red font-redsox text-5xl">
            {item.name}
          </h4>
          {priceString && (
            <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
              <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />
              {priceString}
            </div>
          )}
        </div>

        {/* Description on next line */}
        {item.description && item.description.trim() !== '' && (
          <p className="text-redz-white text-lg font-bold mt-1 ml-2">
            {item.description}
          </p>
        )}
      </div>
    );
  };

  // -------------- BLOCK FOR "Side of Meat" --------------
  const SideOfMeatBlock = () => {
    if (sideOfMeatItems.length === 0) return null;

    const parseMeatName = (fullName: string) =>
      fullName.replace('Side of Meat: ', '');

    return (
      <div className="mb-6">
        {/* Heading */}
        <h4 className="text-redz-red font-redsox text-5xl">Side of Meat</h4>

        {sideOfMeatItems.map(meatItem => {
          const priceString = formatPrice(meatItem.base_price);
          return (
            <div
              className="flex items-center justify-between ml-4 mt-2"
              key={meatItem.id}
            >
              <span className="text-redz-white text-lg font-bold">
                {parseMeatName(meatItem.name)}
              </span>
              {priceString && (
                <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
                  <DollarSign className="w-3 h-3 mr-1 -translate-y-1/3 translate-x-1/4" />
                  {priceString}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // -------------- DRINKS ROW ITEM --------------
  const DrinksRowItem = ({ item }: { item: any }) => {
    const priceString = formatPrice(item.base_price);
    return (
      <div className="mb-4">
        {/* Title + Price in one line */}
        <div className="flex items-center justify-between">
          <h4 className="text-redz-red font-redsox text-5xl">{item.name}</h4>
          {priceString && (
            <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
              <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />
              {priceString}
            </div>
          )}
        </div>

        {/* Description on next line */}
        {item.description && item.description.trim() !== '' && (
          <p className="text-redz-white text-lg font-bold mt-1 ml-2">
            {item.description}
          </p>
        )}
      </div>
    );
  };

  // Filter drinks into Non-Alcoholic vs. Alcoholic subcategories
  const nonAlcDrinks = drinksItems.filter(
    item => item.subcategory?.name === 'Non-Alcoholic'
  );
  const alcDrinks = drinksItems.filter(
    item => item.subcategory?.name === 'Alcoholic'
  );

  return (
    <section className="py-8 px-4">
      {/* 
        narrower container => max-w-5xl 
        split into 2 columns => grid grid-cols-1 md:grid-cols-2 
      */}
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-48">
        
        {/* Left column for Sides */}
        <div id='sides'>
          <SectionTitle title="Sides" alternate />
          {nonSideOfMeatItems.map(item => (
            <StandardRowItem key={item.id} item={item} />
          ))}
          <SideOfMeatBlock />
        </div>

        {/* Right column for Drinks */}
        <div className="relative" id='drinks'>
          <SectionTitle title="Drinks" alternate />

          {/* Non-Alcoholic Drinks first */}
          {nonAlcDrinks.map(item => (
            <DrinksRowItem key={item.id} item={item} />
          ))}

          {/* 21Plus.svg as a divider or icon */}
          <div className="flex items-start my-8 left-0 md:-left-36 relative">
            {/* Slight offset to the left, you can tweak top/left if you prefer absolute */}
            <TwentyOneSvg className="w-1/2 md:w-3/4" />
          </div>

          {/* Alcoholic Drinks */}
          {alcDrinks.map(item => (
            <DrinksRowItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default SidesDrinksSection;