import React from "react";
import { ReactComponent as OurVisionUnderline } from '../../assets/images/OurVisionUnderline.svg';

const OurVision: React.FC = () => {
  return (
    <div className="relative text-center py-24 bg-redz-red space-y-20">
      {/* Container for title + underline */}
      <div className="relative inline-block">
        <h3 className="
          font-nexa 
          text-redz-white 
          text-4xl 
          sm:text-5xl 
          md:text-6xl 
          inline-block
        ">
          Our Vision
        </h3>

        {/* Center the underline below the title */}
        <OurVisionUnderline
          className="
            absolute
            w-auto
            h-[3px]
            md:h-[5px]
            left-1/2
            -translate-x-1/2
            bottom-0
            mt-2
          "
          style={{ top: '100%' }} // Place it just below the h3
        />
      </div>

      {/* Paragraph with improved horizontal margin on large screens */}
      <p 
        className="
          max-w-4xl 
          mx-auto 
          mt-6 
          text-redz-white 
          font-redsox 
          text-xl 
          sm:text-2xl 
          md:text-3xl 
          leading-relaxed 
          text-balance
        "
      >
        At Redz, our vision is to create a dining experience that engages all the 
        senses. From the moment you step through our doors, you are welcomed into 
        a warm and inviting atmosphere designed to make you feel at home. Our 
        carefully curated menu offers a diverse range of dishes, each thoughtfully 
        prepared to delight your palate and leave a lasting impression.
      </p>
    </div>
  );
};

export default OurVision;