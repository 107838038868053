import React from 'react';
import BackgroundWithPinata from './BackgroundWithPinata';
import TitleWithSombrero from './TitleWithSombrero';
import DescriptionText from './DescriptionText';
import {ReactComponent as Testimonials} from '../../../assets/images/Testimonials.svg';
import EventSpaceButton from './EventSpaceButton';

const PrivateEventsSection: React.FC = () => {
    return (
        <BackgroundWithPinata>
            <TitleWithSombrero title="Host Your Private Event at Yona Redz" />
            <DescriptionText text={`
                Looking for the perfect venue for your next celebration? Whether it's a birthday, corporate event, or special occasion, Yona Redz offers an elegant space that will make your event unforgettable. Our dedicated team ensures every detail is covered, so you can enjoy your day.
            `} />
            <DescriptionText text={`
                Our versatile upstairs event space is perfect for any gathering, comfortably accommodating up to 75 guests seated or 100 standing. Enjoy amenities like customizable layouts, audio-visual equipment, and full-service catering.
            `} />
            <DescriptionText text={`
                We host corporate events, weddings, private parties, and holiday celebrations, each with the perfect blend of style and service. Explore our space with a virtual tour or schedule a visit.
            `} />
            <div className='mt-12 hidden sm:block'>
                <Testimonials/>
            </div>

            {/* Call-to-Action Section */}
            <div className="text-center mt-16 space-y-12 lg:mt-24 mb-32">
                <EventSpaceButton />

                <p className="font-myriad text-lg lg:text-xl text-redz-white mb-16">
                    Don't wait! Our space is in high demand. Book your event today and make it an unforgettable experience.
                </p>
            </div>

            {/* Bottom Border SVG */}
            <div className="absolute bottom-[-6em] w-full">
                <img src={"https://redz-bucket.s3.amazonaws.com/EventsSpaceandPhotosBorder.svg"} alt="Event Space and Photos Border" className="w-full h-auto" />
            </div>
        </BackgroundWithPinata>
    );
};

export default PrivateEventsSection;