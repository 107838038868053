import React from "react";
import { ReactComponent as Birth } from "../../assets/images/Birth.svg";
import { ReactComponent as Pinata } from "../../assets/images/Pinata.svg";
import { ReactComponent as OfRedzFire } from "../../assets/images/OfRedzFire.svg";
import SignatureDishSections from "./SignatureDishSections"; // <-- import it
import FramedFresh from "../common/Frame";
import Divider from "../common/Divider";

const SignatureDishBirth: React.FC = () => (
  <div className="relative text-center py-32 bg-redz-white space-y-20">
    <Divider classNameProps="absolute -top-8 w-full z-10" color="black"/>
    
    {/* Pinatas */}
    <div>
      <Pinata className="absolute w-48 top-4 left-8" />
      <Pinata className="absolute w-48 -top-4 right-8" />
    </div>

    {/* Title & Fire Divider */}
    <div className="relative inline-block">
      <Birth className="absolute w-auto h-16 right-1/2 translate-x-1/2 -top-20" />
      <h3
        className="
          font-nexa
          text-redz-red
          text-5xl
          sm:text-6xl
          md:text-7xl
          inline-block
        "
      >
        Signature Dish
      </h3>
      <OfRedzFire className="relative h-10 md:h-16 -translate-x-1/2 left-1/2" />
    </div>

    {/* Main paragraph / introduction */}
    <p
      className="
        text-3xl
        sm:text-4xl
        md:text-5xl
        text-redz-red
        pt-4
        text-balance
        mx-auto
        max-w-xl
        md:max-w-5xl
        font-redsox
        leading-normal
      "
    >
      Yona Redz is more than just a restaurant;
      it's the culmination of a journey that began with a simple idea and grew
      into a beloved community treasure. Explore the milestones that have shaped
      Redz into what it is today
    </p>
    <FramedFresh
    className="block mx-auto w-full max-w-xl"
    imageUrl="https://redz-bucket.s3.amazonaws.com/Lunch%20Special.webp"
    imageWidth={6000}
    imageHeight={4000}
    translateX={-50}
    translateY={5}
    scale={.179}
    preserveAspectRatio="xMidYMid slice"
    />
    {/* NEW: Insert the SignatureDishSections below */}
      <SignatureDishSections />
      <Pinata className="absolute w-24 md:w-32 lg:w-64 bottom-2 md:bottom-4 lg:bottom-16 right-8" />
      <Divider classNameProps="absolute -bottom-4 sm:-bottom-8 w-full z-10" color="white" />
  </div>
);

export default SignatureDishBirth;