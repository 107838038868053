import React, { FC, ReactNode } from "react";
import Footer from "./Footer/Footer";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header/index";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';

// Importing the SVG as a URL
import VerticalBorders from '../../assets/images/VerticalBorders.svg';

interface RootProps {
  children?: ReactNode;
}

const Root: FC<RootProps> = ({ children }) => {
  return (
    <div className="relative flex flex-col min-h-screen text-white bg-black">
      <AnimatePresence>
        <Header />

        {/* 
          LEFT BORDER
          Using inline style for backgroundImage, plus Tailwind for repeat-y and positioning. 
          We do NOT put url(${VerticalBorders}) inside the className, 
          to avoid the PostCSS parse error.
        */}
        <div
          className="
            pointer-events-none
            hidden
            md:block
            absolute
            top-0
            bottom-0
            left-0
            w-8
            z-20
            bg-repeat-y 
            bg-left
          "
          style={{
            backgroundImage: `url(${VerticalBorders})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat-y'
          }}
        />

        {/* 
          RIGHT BORDER
        */}
        <div
          className="
            pointer-events-none
            hidden
            md:block
            absolute
            top-0
            bottom-0
            right-0
            w-8
            z-20
            bg-repeat-y
            bg-right
          "
          style={{
            backgroundImage: `url(${VerticalBorders})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat-y'
          }}
        />

        {/* MAIN CONTENT */}
        <div className="flex-grow relative overflow-x-hidden">
          {children}
        </div>

        <Footer />

        <ToastContainer 
          position="top-right" 
          className='mt-36'
          autoClose={2500} 
        />
        <ScrollRestoration 
          getKey={(location) => location.pathname} 
        />
      </AnimatePresence>
    </div>
  );
};

export default Root;