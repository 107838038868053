import React from "react";
import Banner from "../common/Banner";

// Reuse the same paragraph styling from your snippet
const paragraphClass = `
  text-black
  font-myriad
  text-balance
  text-sm
  md:text-base
  lg:text-lg
  leading-relaxed
  text-center
  mt-4
`;

interface SectionContent {
  title: string;
  text: string;
}

const signatureDishSections: SectionContent[] = [
  {
    title: "Inspiration and Creation",
    text: `Yona first encountered birria on a trip to Mexico, where he was mesmerized by the slow-cooked, deeply flavorful stew made from tender cuts of meat and a complex blend of spices. Determined to master this dish, Yona spent months perfecting his recipe, ensuring that every bite would transport diners to the streets of Jalisco.`,
  },
  {
    title: "The Process",
    text: `What makes our birria tacos so special is the meticulous preparation involved. Each batch is slow-cooked for hours, allowing the meat to soak in the rich flavors of the broth. The result is a taco that is both succulent and bursting with taste—a true labor of love.`,
  },
  {
    title: "Why It's a Favorite",
    text: `Our customers rave about the birria tacos not only for their incredible flavor but also for the experience they provide. There's something magical about biting into a taco that's been crafted with such care, and it's a taste that keeps people coming back time and time again.`,
  },
];

const SignatureDishSections: React.FC = () => {
  return (
    <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-4 mx-10 justify-items-center">
      {signatureDishSections.map((section, idx) => (
        <div
          className={`w-full max-w-2xl px-4 py-8 items-center ${
            idx === signatureDishSections.length - 1 && idx % 2 === 0
              ? "md:col-span-2"
              : ''
          }`}
          key={section.title}
        >
          <div className="relative w-full">
            {/* Reuse the Banner for the heading background */}
            <Banner classNameProp="w-full h-auto" color={'red'}/>
            <h2
              className="
                text-redz-white
                font-redsox
                absolute
                top-1/2
                left-1/2
                -translate-x-1/2
                -translate-y-1/2
                text-xl
                md:text-2xl
                lg:text-3xl
                whitespace-nowrap
              "
            >
              {section.title}
            </h2>
          </div>

          {/* Paragraph text below the banner */}
          <p className={paragraphClass}>{section.text}</p>
        </div>
      ))}
    </div>
  );
};

export default SignatureDishSections;